import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Dicing tools`}</h4>
    <p>{`Dicing tools cut individual chips (dies) in the wafer into separated chips. This process also involves thinning the wafer. Dicing tools include blade saws, laser saws, dicing accessories and backside grinding equipment.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      